<template>

    <div class='grid'>
        <confirm-dialog></confirm-dialog>
        <div class='col-12 md:col-6 lg:col-12'>
            <div class='p-0 h-full flex flex-column'>

                <div class='grid'>
                    <div class='col-12 lg:col-8'>
                        <h5 class='mb-0 mt-3'><i class="pi pi-check mr-2"></i> Pasif Personel Listesi</h5>
                    </div>

                    <div class='col-12 lg:col-4 text-right'>
                        <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger'/>
                                <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
                                    <h3>Pasif Personel Listesi</h3>
                                    <p class="text-lg">
                                        İşten herhangi bir nedenle ayrılmış personellerin listesini içerir.<br>
                                        <br>
                                        Buradaki maaş bilgileri personelin son aldığı maaşı gösterir. İşten çıkmış personel ile ilgili mesai, izin ve avans işlemleri yapılamaz.<br>
                                        <br>
                                        Maaş gün hesaplaması son dönem çalışma takviminde yer almaktadır.<br>
                                        <br>
                                        <b>Kontrol Durumu:</b> Mali müşavirliğin personelin SGK çıkışını yaptığı durumlarda müşavir tarafından iş takibi açısından işaretlenir.<br>
                                        <br>
                                        Proje değişiklerinde personel ilgili işe başladığı projede yeniden işe alınmalıdır.<br>
                                        <br>
                                        Yanlışlıkla veya vazgeçilen işten çıkarılma işlemleri o dönemin maaşı hesaplanmamış ise admin tarafından iptal edilebilir. Proje müdürleri bu işlemi yapamaz. 
                                    </p>
                                </Sidebar>
                    </div>
                </div>

                <DataTable :value='dataList' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id' :rowHover='true' v-model:filters='filters' filterDisplay='menu' :loading='loadingIndicator' :filters='filters' responsiveLayout='scroll' :globalFilterFields="['project.name','employee.name','employee.surName','employee.identityNumber','employee.gsm','passiveManagerUser.name','passiveReason']">

                    <template #header>
                        <div class='grid'>
                            <div class="col-12 lg:col-8 pb-0">
                                <Dropdown :model-value='selectedProject' :options='projectList' option-value='id' option-label='name' @change='projectChange' ></Dropdown>
                            </div>

                            <div class="col-12 lg:col-4 text-right pb-0">
                                <span class='p-input-icon-left mr-2'>
                                    <i class='pi pi-search' />
                                    <InputText v-model="filters['global'].value" placeholder='Arama' style='width: 100%' />
                                </span>
                            </div>
                        </div>
                    </template>

                    <template #empty>
                        Personel bulunamadı.
                    </template>

                    <template #loading>
                        Pasif personel listesi yükleniyor. Lütfen bekleyin.
                    </template>

                    <Column header='Profil' bodyClass="text-center" style='width:4rem'>
                            <template #body='{data}'>
                                <img :alt='data.employeePhoto' :src="'http://image.a1catering.com.tr/' + data.employee.employeePhoto" class='border-circle border-0 border-solid cursor-pointer' width='80' height='80' style='vertical-align: middle' @click='edit(data)'/>
                            </template>
                    </Column>

                    <Column field='employee.name' header='Ad' style='width:13rem'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.name }}
                                </a>
                        </template>
                    </Column>

                    <Column field='employee.surName' header='Soyad' style='width:13rem'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.surName }}
                                </a>
                        </template>
                    </Column>

                    <Column field='project.name' header='Proje' style='width:15rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.project.name }}
                        </template>
                    </Column>

                    <Column field='employee.identityNumber' header='TC Kimlik' filterField='employee.identityNumber' :showFilterMatchModes='false' style='width:8rem'>
                    </Column>

                    <Column field='employee.gsm' header='GSM' filterField='employee.gsm' :showFilterMatchModes='false' style='width:8rem'>
                        <template #body='{data}'>
                            <a :href="'tel:+90' + data.employee.gsm" v-if='data.employee.gsm != null && data.employee.gsm != ""'>{{ data.employee.gsm }}</a>
                        </template>
                    </Column>

                    <Column header='Maaş' filterField='officialSalary' dataType='date' style='width:9rem'>
                        <template #body='{data}'>
                            <b class="text-green-600">{{ formatPrice(data.officialSalary) }} &#8378;</b>
                        </template>
                    </Column>

                    <Column header='Diğer Maaş' filterField='startDate' dataType='date' style='width:9rem'>
                        <template #body='{data}'>
                            <b class="text-red-600">{{ formatPrice(data.unOfficialSalary) }} &#8378;</b>
                        </template>
                    </Column>

                    <Column header='Toplam Maaş' filterField='startDate' dataType='date' style='width:9rem'>
                        <template #body='{data}'>
                            <b>{{ formatPrice(data.unOfficialSalary + data.officialSalary) }} &#8378;</b>
                        </template>
                    </Column>

                    <Column header='Giriş Tarihi' filterField='startDate' dataType='date' style='width:7rem'>
                        <template #body='{data}'>
                            {{ formatDate(data.startDate) }}
                        </template>
                    </Column>

                    <Column header='Çıkış Tarihi' filterField='endDate' dataType='date' style='width:7rem'>
                        <template #body='{data}'>
                            {{ formatDate(data.endDate) }}
                        </template>
                    </Column>
                    <Column header='Çıkış Sebebi' filterField='passiveReason' style='width:10rem'>
                        <template #body='{data}'>
                            {{ data.passiveReason }}
                        </template>
                    </Column>
                    <Column header='İşlem Tarihi' filterField='passiveProcessDate' dataType='date' style='width:7rem'>
                        <template #body='{data}'>
                            {{ data.passiveProcessDate != null ? formatDate(data.passiveProcessDate) : "-" }}
                        </template>
                    </Column>

                    <Column header='İşlem Yapan' filterField='passiveManagerUser.name' dataType='date' style='width:7rem'>
                        <template #body='{data}'>
                            {{ data.passiveManagerUser != null ? data.passiveManagerUser.name + " " + data.passiveManagerUser.surName : "-" }}
                        </template>
                    </Column>

                    <Column field='verified' header='Durum' dataType='boolean' bodyClass='text-center' style='width:4rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isActive, 'text-pink-500 pi-times-circle': !data.isActive}"></i>
                        </template>
                    </Column>

                    <Column field='isRetired' header='Emekli Durum' dataType='boolean' bodyClass='text-center' style='width:4rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isRetired, 'text-pink-500 pi-times-circle': !data.isRetired}"></i>
                        </template>
                    </Column>

                    <Column field='verified' header='Kontrol Durumu' dataType='boolean' bodyClass='text-center' style='width:4rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isPassiveApproved, 'text-pink-500 pi-times-circle': !data.isPassiveApproved}" @click='changePassiveApproved(data.id, ! data.isPassiveApproved)'></i>
                        </template>
                    </Column>

                    <Column header="Görüntüle" bodyClass="text-center" style="width:4rem">
                        <template #body="slotProps">
                            <Button icon="pi pi-search" class="p-button-rounded p-button-primary mr-2" @click="edit(slotProps.data)"/>
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import EmployeeService from '../../services/employeeService';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import store from '@/store';
import { showSuccessMessage } from '../../components/shared/toast/toastFunction';
import { checkUserRole, getProjects } from '../common/commonConstantFunctions';

export default {
    data() {
        return {
            visibleLeft:false,
            selectedProject:-1,
            projectList:[],
            dataList: [],
            filters: null,
            loadingIndicator: true,
            salaryReport:{},
            employeeListReport:{
                totalPersonal : 0,
                totalPrice : 0,
                totalOfficialPrice:0,
                totalUnOfficialPrice:0
            },
        };
    },
    _firmService: null,
    _projectEmployeeService: null,
    created() {
        checkUserRole(this, "Admin");
        this.projectList = getProjects();
        this.projectId = store.getters.getActiveProject.id;
        this._employeeService = new EmployeeService();
        this._projectEmployeeService = new ProjectEmployeeService();
        this.initFilters();
        this.loadingIndicator = true;
    },

    async mounted() {
        await this.refreshProjectEmployeeList();
    },
    methods: {
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        async projectChange(event) {
            this.selectedProject = event.value;
            await this.refreshProjectEmployeeList();
        },
        async exportExcel() {
            this.loadingIndicator = true;
            let excelExports = await this._projectEmployeeService.getProjectEmployeesHistoryExcel(this.selectedProject);
            let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Proje Personel Listesi " + moment(this.paymentDate).format('DD.MM.YYYY') + ".xlsx";
            link.click();
            this.loadingIndicator = false;
        },
        async refreshProjectEmployeeList() {
            const response = await this._projectEmployeeService.getProjectEmployeesHistory(this.selectedProject);
            if (response.isSuccess) {
                this.dataList = response.data;
            }

            this.loadingIndicator = false;
        },

        clearFilter() {
            this.initFilters();
        },

        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        edit(item) {
            this.$router.push({ name: 'editProjectEmployee', params: { employeeId: item.employeeId, projectEmployeeId: item.id } });
        },
        async changePassiveApproved(id, newStatus) {
            this.$confirm.require({
                message: 'Kontrol Statüsünü Değiştirmek İstediğinize Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let response = await this._projectEmployeeService.updatePassiveStatus(id, newStatus);
                    if (response.isSuccess) {
                        showSuccessMessage(this, "Kontrol Statüsü Değişti");
                    }
                    await this.refreshProjectEmployeeList();
                }
            });

        },
    },
};
</script>

